import React, { useState } from "react";
import Typography from "../../../../components/Typography/Typography";
import Button from "../../../../components/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import ToastComponent from "../../../../components/Toast/ToastComponent";
import { useSelector } from "react-redux";
import AddForm from "../../../../components/forms/AddForm";
import { useCreateAssetTypeMutation } from "../../../../services/supplyChainService";
import { useModal } from "../../../../hooks/modalsHooks";
import { toast } from "react-toastify";
import { useGetProjectDetailQuery } from "../../../../services/projectService";
import { logEvent } from "../../../../utils/amplitudeUtlis";
import * as Yup from "yup";

export default function AddAsset() {
    const navigate = useNavigate();
    const { showModal } = useModal();
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [createAssetType] = useCreateAssetTypeMutation();
    const {
        data: project,
        error,
        isProjectLoading,
    } = useGetProjectDetailQuery(id);
    const handleCloseClick = () => {
        navigate(-1);
        logEvent({
            eventName: "cancel_button_clicked",
            eventProperties: {
                page_name: "Add asset Page",
            },
        });
    };

    const handleAddAsset = async (values) => {
        setIsLoading(true);
        logEvent({
            eventName: "record_type_creation_initiated",
            eventProperties: {},
        });
        try {
            const addAssetsTypeResponse = await createAssetType({
                id: id,
                asset_type_name: values.assetTitle,
                unit_category:values.unitCategory
            });

            if (addAssetsTypeResponse?.data?.errors) {
                throw new Error("Error in creating record type.");
            }

            logEvent({
                eventName: "record_type_creation_completed",
                eventProperties: {
                    type_name: values.assetTitle,
                    record_type_id: addAssetsTypeResponse?.data.data.id,
                },
            });

            toast("Record type created.");
            navigate(
                `/overview/${id}/asset-type/${addAssetsTypeResponse?.data.data.id}/edit`
            );
        } catch (error) {
            logEvent({
                eventName: "record_type_creation_failed",
                eventProperties: {
                    error: error.message,
                },
            });

            toast.error("Error in creating record type.");
        } finally {
            setIsLoading(false);
        }
    };

    const validationSchema = Yup.object({
        assetTitle: Yup.string()
            .trim("The input must not be empty or just spaces")
            .required("The input must not be empty or just spaces"),
    });

    return (
        <div className="project-container h-100 min-vh-100 w-100 px-4">
            <div className="pt-3">
                <Typography variant="body1">
                    Overview / {project?.data?.name} / Add record type
                </Typography>
                <div className="d-flex flex-column flex-sm-row justify-content-md-start justify-content-center align-items-center pt-3 pb-2 mb-3">
                    <div className="d-flex align-items-center flex-column flex-sm-row">
                        <Typography variant="h1">Add record type</Typography>
                    </div>
                    <div className="me-0 mb-2 mb-sm-0 p-md-0 p-4 pt-3 pe-md-1 ms-sm-auto d-flex justify-content-center justify-content-sm-start">
                        <Button
                            type="button"
                            variant="secondary"
                            fullWidth={false}
                            onClick={handleCloseClick}
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
                <div className="mt-4 divider-line"></div>
            </div>
            <div className="container col-xxl-8 px-4 pt-4">
                <div className="row align-items-center justify-content-center d-flex flex-row">
                    <div className="col-lg-10 mx-auto justify-content-center">
                        <AddForm
                            title="Add Asset"
                            type="asset"
                            count={1}
                            initialValues={{ assetTitle: "" }}
                            onSubmit={handleAddAsset}
                            isLoading={isLoading}
                            validationSchema={validationSchema}
                        />
                        {errorMessage && (
                            <ToastComponent message={errorMessage} />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
