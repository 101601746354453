import { useEffect, useState } from "react";
import { ACCESS_TOKEN_NAME } from "../constants";
import {  } from "../services/authService";
import { useLazyProfileQuery, useProfileQuery } from "../services/userService";
import { useAddress, useUser, useWallet } from "@thirdweb-dev/react";

export const useLoginStatus = () => {
    if (localStorage.getItem(ACCESS_TOKEN_NAME)) {
        return true;
    }
    return false;
};

export const useProfile = async () => {
    const { data: profileData } = useProfileQuery();
    let user = profileData?.data || null;

    let wallet = useWallet("embeddedWallet");
    let walletEmail = await wallet?.getEmail();

    const getUser = () => {
        return user;
    }
    
    return {
        user,
        wallet,
        walletEmail,
        getUser
    }
}

