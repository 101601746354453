import { useState } from "react";
import Typography from "../../../../components/Typography/Typography";
import Button from "../../../../components/Button/Button";
import "../../project.scss";
import { useNavigate, useParams } from "react-router-dom";
import ToastComponent from "../../../../components/Toast/ToastComponent";
import AddForm from "../../../../components/forms/AddForm";
import { useCreateNodeMutation } from "../../../../services/supplyChainService";
import { toast } from "react-toastify";
import { useGetProjectDetailQuery } from "../../../../services/projectService";
import { logEvent } from "../../../../utils/amplitudeUtlis";
import * as Yup from "yup";
import { useLazySearchLocationsQuery } from "../../../../services/locationService";

export default function AddNode() {
    const { id } = useParams();
    const [toastMessage] = useState("");
    const [showToast] = useState(false);
    const navigate = useNavigate();
    const [addNode] = useCreateNodeMutation();
    const [isLoading, setIsLoading] = useState(false);

    const {
        data: project,
        error,
        projectIsLoading,
    } = useGetProjectDetailQuery(id);

    const validationSchema = Yup.object().shape({
        nodeTitle: Yup.string()
            .trim("The input must not be empty or just spaces")
            .required("The input must not be empty or just spaces"),
        description: Yup.string()
            .trim("The input must not be empty or just spaces")
            .required("The input must not be empty or just spaces"),
        location: Yup.object()
            .shape({
                name: Yup.string().required("Location name is required"),
                meta: Yup.object().shape({
                    lon: Yup.string().required("Longitude is required"),
                    lat: Yup.string().required("Latitude is required"),
                }),
            })
            .required("Location is required"),
        nodeImage: Yup.string()
            .nullable(), // Allow null during form handling
    });

    const handleCloseClick = async () => {
        navigate(-1);
        logEvent({
            eventName: "cancel_button_clicked",
            eventProperties: {
                page_name: "Add Node Page",
            },
        });
    };

    const handleAddNodeSubmit = async (values) => {
        setIsLoading(true);
        logEvent({
            eventName: "node_creation_initiated",
            eventProperties: {},
        });

        try {
            const addNodeResponse = await addNode({
                id: id,
                name: values.nodeTitle,
                description: values.description,
                location: values.location.name,
                location_meta_data: values.location.meta,
                node_image: values.nodeImage,
            });

            if (addNodeResponse?.error) {
                throw "Error in creating node.";
            }

            toast("Node created.");
            logEvent({
                eventName: "node_creation_completed",
                eventProperties: {
                    node_name: values.nodeTitle,
                    node_id: addNodeResponse.data.id,
                },
            });

            navigate(`/overview/${id}/configuration`);
        } catch {
            toast.error("Error with node data");
            logEvent({
                eventName: "node_creation_failed",
                eventProperties: {
                    errorMessage: "Error with node data",
                },
            });
        } finally {
            setIsLoading(false);
        }
    };

    const initialValues = {
        nodeTitle: "",
        description: "",
        location: { name: "", meta: {} },
        nodeImage: "",
    };

    return (
        <div className="container-fluid project-container h-100 min-vh-100 w-100 m-0 p-0 mb-3">
            <div className="text-container">
                <Typography className="ps-4 pt-3" variant="body1">
                    Overview / {project?.data?.name} / Add Node
                </Typography>
                {showToast && (
                    <ToastComponent message={toastMessage} type="Error" />
                )}
                <div className="d-flex flex-column flex-sm-row justify-content-md-start justify-content-center align-items-center pt-3 pb-2 mb-3">
                    <div className="d-flex align-items-center flex-column flex-sm-row">
                        <Typography className="ps-md-4 ps-0" variant="h1">
                            Add nodes
                        </Typography>
                    </div>
                    <div className="me-0 mb-2 mb-sm-0 p-md-0 p-4 pt-3 pe-md-4 ms-sm-auto d-flex justify-content-center justify-content-sm-start">
                        <Button
                            type="button"
                            variant="secondary"
                            fullWidth={false}
                            onClick={handleCloseClick}
                        >
                            Cancel
                        </Button>
                    </div>
                </div>

                <div className="m-4 divider-line"></div>
            </div>
            <div className="container col-xxl-8 px-4 pt-4">
                <div className="row align-items-center justify-content-center d-flex flex-row">
                    <div className="col-lg-10 mx-auto justify-content-center">
                        <AddForm
                            title="Add Node"
                            type="node"
                            count={1}
                            onSubmit={handleAddNodeSubmit}
                            initialValues={initialValues}
                            isLoading={isLoading}
                            validationSchema={validationSchema}
                            searchLocationsHook={useLazySearchLocationsQuery}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
