import { useState, useMemo, useEffect } from "react";
import Typography from "../../../../components/Typography/Typography";
import Button from "../../../../components/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import { Form, TextField } from "../../../../components/forms";
import {
    useGetAssetsTypeQuery,
    useGetOneAssetsTypeQuery,
    useUpdateAssetTypeMutation,
} from "../../../../services/supplyChainService";

import "./asset.scss";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { logEvent } from "../../../../utils/amplitudeUtlis";
import { FormRenderer } from "./FormRenderer";
import SearchableInput from "../../../../components/forms/Search";
export default function EditAssetPage() {
    const navigate = useNavigate();
    const { id, assetTypeId } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const { data: selectedAssetTypeResponse } = useGetOneAssetsTypeQuery(
        { id, assetTypeId },
        { skip: !id },
    );
    const { data: assetTypesResponse } = useGetAssetsTypeQuery(id, {
        skip: !id,
    });

    const assetTypes = useMemo(() => {
        if (!assetTypesResponse?.data) {
            return [];
        }
        return assetTypesResponse.data
            .filter((at) => at.id !== assetTypeId)
            .map((at) => ({
                label: at.type_name,
                value: at.type_name,
                id: at.id,
            }));
    }, [assetTypesResponse, assetTypeId]);

    const _formFields = useMemo(() => {
        return selectedAssetTypeResponse?.data?.form_config?.fields || [];
    }, [selectedAssetTypeResponse]);

    const [formFields, setFormFields] = useState([]);
    const [formGroups, setFormGroups] = useState([]);
    const [selectedDependencies, setSelectedDependencies] = useState([]);
    const [showDependencySearch, setShowDependencySearch] = useState(false);
    const [updateAssetType] = useUpdateAssetTypeMutation();

    useEffect(() => {
        setFormFields(_formFields);
    }, [_formFields]);

    useEffect(() => {
        const dependencies =
            selectedAssetTypeResponse?.data?.dependency_config?.dependencies ||
            [];
        if (dependencies.length > 0) {
            setShowDependencySearch(true);
            setSelectedDependencies(
                dependencies.map((dep) => ({
                    id: dep.asset_type_id,
                    value: dep.asset_value,
                    label: assetTypes.find((at) => at.id === dep.asset_type_id)
                        ?.label,
                })),
            );
        }
    }, [selectedAssetTypeResponse, assetTypes]);

    const handleCloseClick = () => {
        navigate(-1);
        logEvent({
            eventName: "cancel_button_clicked",
            eventProperties: {
                page_name: "Edit Asset Page",
            },
        });
    };

    const handleAddAsset = async (values) => {
        setIsLoading(true);
        let _dependencyConfig = {
            dependencies: selectedDependencies.map((dep) => ({
                asset_type_id: dep.id,
                asset_value: dep.value,
            })),
        };

        try {
            const updateAssetResponse = await updateAssetType({
                id: id,
                assetTypeId: assetTypeId,
                form_config: {
                    fields: formFields,
                },
                dependency_config: _dependencyConfig,
            });
            if (updateAssetResponse?.data.errors) {
                logEvent({
                    eventName: "record_type_update_error",
                    eventProperties: {
                        errorMessage: updateAssetResponse?.data.errors,
                    },
                });
                throw new Error("Error in updating record type.");
            }
            logEvent({
                eventName: "record_type_updated",
                eventProperties: {
                    assetTypeId: assetTypeId,
                },
            });
            navigate(`/overview/${id}/configuration`);
            toast("Record type updated.");
        } catch (error) {
            toast("Error in updating record type");
            logEvent({
                eventName: "record_type_update_error",
                eventProperties: {
                    errorMessage: error.message,
                },
            });
        } finally {
            setIsLoading(false);
        }
    };

    const generateInitialValues = () => {
        let values = {
            type_name: selectedAssetTypeResponse?.data.type_name || "",
            unit_category:
                selectedAssetTypeResponse?.data.unit_category.name || "",
            dependency: {},
        };
        formFields.forEach((field, index) => {
            values[`form_config_label_${index}`] = field.label || "";
            values[`form_config_type_${index}`] = field.type || "";
        });
        const dependencies =
            selectedAssetTypeResponse?.data?.dependency_config?.dependencies ||
            [];
        dependencies.forEach((dep) => {
            values.dependency[dep.asset_type_id] = dep.asset_value || "";
        });

        return values;
    };

    return (
        <div className="project-container h-100 min-vh-100 w-100 px-4">
            <div className="pt-3">
                <Typography variant="body1">
                    Overview / Edit record type
                </Typography>
                <div className="d-flex justify-content-center flex-wrap flex-md-nowrap align-items-center d-md-flex justify-content-md-between">
                    <div className="d-flex align-items-center flex-column flex-md-row">
                        <Typography variant="h1">Edit record type</Typography>
                    </div>
                    <div className="me-0 mb-2 mb-md-0 p-md-0 p-4 pt-3">
                        <Button
                            type="button"
                            variant="secondary"
                            fullWidth={false}
                            onClick={handleCloseClick}
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
                <div className="mt-4 divider-line"></div>
            </div>
            <div className="container col-xxl-8 px-4 pt-4">
                <div className="row align-items-center justify-content-center d-flex flex-row">
                    <Form
                        initialValues={generateInitialValues()}
                        onSubmit={handleAddAsset}
                        enableReinitialize={true}
                    >
                        <div className="m-3">
                            <TextField
                                name="type_name"
                                label="Record Type"
                                disabled={true}
                            />
                        </div>
                        <div className="m-3">
                            <TextField
                                name="unit_category"
                                label="Unit Category"
                                disabled={true}
                            />
                        </div>
                        <div className="m-3">
                            <div className="toggle-section">
                                <div className="toggle-header">
                                    <div className="toggle-label">
                                        Configure Asset Dependencies
                                    </div>
                                    <div className="form-check form-switch">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            role="switch"
                                            id="assetDependencyToggle"
                                            checked={showDependencySearch}
                                            onChange={() => {
                                                setShowDependencySearch(
                                                    !showDependencySearch,
                                                );
                                                if (showDependencySearch) {
                                                    setSelectedDependencies([]);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>

                                {showDependencySearch && (
                                    <div className="toggle-content">
                                        <SearchableInput
                                            name="dependency"
                                            label="Search Dependencies"
                                            options={assetTypes}
                                            onSelect={(option) => {
                                                if (
                                                    !selectedDependencies.find(
                                                        (dep) =>
                                                            dep.value ===
                                                            option.value,
                                                    )
                                                ) {
                                                    setSelectedDependencies([
                                                        ...selectedDependencies,
                                                        {
                                                            ...option,
                                                            id: option.id,
                                                        },
                                                    ]);
                                                }
                                            }}
                                        />
                                        <div className="selected-dependencies mt-3">
                                            {selectedDependencies.map(
                                                (dep, index) => (
                                                    <span
                                                        key={index}
                                                        className="badge me-2 mb-2 p-2"
                                                    >
                                                        {dep.label
                                                            .charAt(0)
                                                            .toUpperCase() +
                                                            dep.label.slice(1)}
                                                        <i
                                                            className="bi bi-x-circle ms-2"
                                                            style={{
                                                                cursor: "pointer",
                                                                fontSize:
                                                                    "11px",
                                                                color: "#1C8AD1",
                                                            }}
                                                            onClick={() => {
                                                                setSelectedDependencies(
                                                                    selectedDependencies.filter(
                                                                        (
                                                                            _,
                                                                            i,
                                                                        ) =>
                                                                            i !==
                                                                            index,
                                                                    ),
                                                                );
                                                            }}
                                                        ></i>
                                                    </span>
                                                ),
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <FormRenderer
                            formFields={formFields}
                            setFormFields={setFormFields}
                            formGroups={formGroups}
                            setFormGroups={setFormGroups}
                        />

                        <div className="m-3">
                            <Button
                                type="submit"
                                variant="primary"
                                fullWidth={false}
                                isLoading={isLoading}
                            >
                                Save
                            </Button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    );
}
