import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const locationApi = createApi({
    reducerPath: 'locationApi',
    baseQuery: fetchBaseQuery({ baseUrl: 'https://nominatim.openstreetmap.org/' }),
    endpoints: (builder) => ({
        searchLocations: builder.query({
            query: (searchQuery) => `search?format=json&q=${encodeURIComponent(searchQuery)}&limit=5`,
        }),
    }),
});

export const { useSearchLocationsQuery, useLazySearchLocationsQuery } = locationApi;