import React, { useEffect, useState } from "react";
import "./form.scss";
import { useField } from "formik";
import Typography from "../Typography/Typography";

const ImageUpload = (props) => {
    const { name, width, height, onFileSelected, logoImage, label, ...rest } =
        props;
    const [field, meta, helpers] = useField(props);
    const [file, setFile] = useState(null);
    const [preview, setPreview] = useState(logoImage?.file);

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);
            setPreview(URL.createObjectURL(selectedFile));
        }
    };

    const handleDrop = (event) => {
        event.preventDefault();
        const droppedFile = event.dataTransfer.files[0];
        if (droppedFile) {
            setFile(droppedFile);
            setPreview(URL.createObjectURL(droppedFile));
        }
    };

    const handleRemoveFile = () => {
        setFile(null);
        setPreview(null);
    };

    useEffect(() => {
        props.onFileSelected(file);
    }, [file, props.onFileSelected]);

    useEffect(() => {
        if (file) {
            setPreview(URL.createObjectURL(file));
        } else {
            setPreview(logoImage?.file); 
        }
    }, [file, logoImage]);

    return (
        <div className="w-100">
            {label && (
                <label htmlFor={name} className="form-label">
                    {label}
                </label>
            )}
            <section
                className="drag-drop d-flex flex-column"
                style={{ width: props.width, height: props.height }}
                onDrop={handleDrop}
                onDragOver={(event) => event.preventDefault()}
            >
                <div
                    className={`document-uploader ${
                        file ? "upload-box active" : "upload-box"
                    }`}
                >
                    <>
                        <div className="upload-info d-flex flex-column align-items-center">
                            <i className="bi bi-cloud-arrow-up upload-icon"></i>
                            <Typography
                                variant="body1"
                                className="fw-bold mb-1"
                            >
                                Drag and drop your file here, or{" "}
                                <label
                                    htmlFor={props.name}
                                    className="browse-btn btn-link p-0"
                                >
                                    browse
                                </label>
                            </Typography>
                            <Typography variant="body1" className="text-muted">
                                Limit 2MB per file. Supported files: .JPG,
                                .JPEG, .PNG
                            </Typography>
                        </div>
                        <input
                            id={props.name}
                            {...rest}
                            type="file"
                            hidden
                            onChange={(e) => {
                                const file = e.currentTarget.files[0];
                                helpers.setValue(file);
                                if (props.onChange) {
                                    props.onChange(e);
                                }
                                handleFileChange(e);
                            }}
                            accept=".jpg,.jpeg,.png"
                            multiple={false}
                        />
                    </>

                    {preview && (
                        <div className="file-list d-flex flex-column gap-2 w-100 h-30">
                            <div className="file-list__container w-100 h-100 overflow-auto">
                                <div className="file-item d-flex justify-content-between align-items-center p-2 border rounded">
                                    <div className="file-info d-flex align-items-center gap-1 flex-grow-1">
                                        <img
                                            src={preview}
                                            alt="Preview"
                                            height="80px"
                                            width="80px"
                                        />
                                        <Typography
                                            variant="body1"
                                            className="ms-2 m-0"
                                        >
                                            {file
                                                ? file.name
                                                : logoImage.file_name}
                                        </Typography>
                                    </div>
                                    <div className="file-actions cursor-pointer">
                                        <i
                                            className="bi bi-x fs-3 text-secondary"
                                            onClick={handleRemoveFile}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                {meta.touched && meta.error ? (
                    <div className="invalid-feedback d-block">{meta.error}</div>
                ) : null}
            </section>
        </div>
    );
};

export default ImageUpload;
