import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Navbar from "./Navbar/Navbar";
import Breadcrumbs from "./Breadcrumbs";
import { useLazyProfileQuery, useProfileQuery } from "../services/userService";
import { useProfile } from "../hooks/authHooks";
import Sidebar from "./Sidebar/Sidebar";
import Loading from "./LoadingSkeleton/Loading";

export default function AppLayout({ userType }) {
    const { data: profileData, isLoading, refetch } = useProfileQuery();
    let user = profileData?.data || null;
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (location.pathname === "/") {
            navigate("/signin");
        }
        if (user?.id != null) {
            if (user?.tenant == null) {
                navigate("/onboarding");
            } else {
                navigate(location.pathname);
            }
        } else {
            refetch();
        }
    }, [user, isLoading]);

    return (
        <>
            <Navbar user={user} />
            <div className="container-fluid">
                <main style={{ paddingTop: "60px" }}>
                    {/* //Todo: Add breadcrumbs */}
                    {user !== null && <Outlet />}
                </main>
            </div>
        </>
    );
}
