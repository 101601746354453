import { useEffect, useState } from "react";
import "./trace.scss";
import Typography from "../../../components/Typography/Typography";
import { Icon } from "../../../components/Icon";
import { useNavigate, useParams } from "react-router-dom";
import { useLazyGetTraceAssetQuery } from "../../../services/supplyChainService";

export default function TracePage() {
    const navigate = useNavigate();
    const { projectId, assetId } = useParams();

    const [assetTrace, setAssetTrace] = useState(null);
    const [nodeData, setNodeData] = useState(null);
    const [compositionData, setCompositionData] = useState(null);
    const [productName, setProductName] = useState("Trace Product");
    const [projectName, setProjectName] = useState(null);

    const [getTrace, results] = useLazyGetTraceAssetQuery();

    useEffect(() => {
        handleTraceAsset();
    }, [projectId, assetId]);

    const handleTraceAsset = async () => {
        try {
            const trace = await getTrace({
                id: projectId,
                asset_id: assetId,
            });

            if (trace.error) {
                throw "Error in trace";
            }

            setAssetTrace(trace.data.data);


            let _nodeData = [];
            for (var key in trace.data.data) {
                for (var asset of trace.data.data[key]) {
                    // Try to get the date from root level date field
                    if(asset.form_data && Array.isArray(asset.form_data)){
                        console.log(asset.form_data);
                        let dateField = asset.form_data.find(data => data.name && data.name.includes("date"));
                        
                        // TODO: refactor this case
                        if(!dateField && asset.form_data.length > 0 && asset.form_data[0].type === 'group'){
                            if(asset.form_data[0].value.length > 0){
                                dateField = asset.form_data[0].value[0].find(data => data.name && data.name.includes("date"));
                            }
                        }
                        
                        if(dateField){
                            _nodeData.push({
                                name: asset.node.name,
                                date: new Date(dateField.value).toISOString().split('T')[0],
                            });
                            continue;
                        }
                    }

                    _nodeData.push({
                        name: asset.node.name,
                        date: asset.created_at.split("T")[0],
                    });
                }
            }
            setNodeData(_nodeData);

            if (trace.data.data[0]) {
                setProductName(trace.data.data[0][0]["asset_id"]);
                setProjectName(
                    trace.data.data[0][0]["supply_chain"]["project"]["name"]
                );
            }

            let _compositionData = [];
            for (var key in trace.data.data) {
                for (var asset of trace.data.data[key]) {
                    _compositionData.push({
                        asset: asset.asset_id,
                        type: asset.asset_type.type_name,
                    });
                }
            }
            // console.log(_compositionData);
            setCompositionData(_compositionData);
        } catch (error) {
            console.log(error);
        }
    };

    const handleBackClick = () => {
        navigate(`/app/${projectId}`);
    };

    const handleViewDetails = () => {
        navigate(`/app/${projectId}/trace/${assetId}/more`);
    };

    if (!nodeData) {
        return (
            <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "100vh" }}
            >
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }

    return (
        <div className="container-fluid landing-container min-vh-100 h-100">
            <div className="landing-header d-flex align-items-center justify-content-center">
                <img
                    src="/images/hyperglade-logo-black.svg"
                    alt="logo"
                    height={50}
                    width={120}
                />
            </div>
            <div className="pt-2 ps-3 ps-md-0 col-md-12">
                <span>
                    <Typography
                        variant="body1"
                        className="m-0  d-flex justify-content-md-center"
                    >
                        Trace / {productName}
                    </Typography>
                </span>
            </div>
            <div className="pt-2 ps-3 ps-md-0 d-flex justify-content-md-center">
                <Typography variant="body1" color="#70B8FF" className="m-0">
                    {productName}
                </Typography>
            </div>
            <div className=" pt-2 ps-3 ps-md-0 d-flex justify-content-md-center">
                <Typography variant="display3" className="m-0">
                    {projectName}
                </Typography>
            </div>

            <div className="px-2">
                <div className="row m-0">
                    <div className="col-12 col-md-8 offset-md-2 pt-3">
                        {/* <CarousalImageCard images={images} /> */}
                        <div className="d-flex justify-content-center mt-3 text-start mx-2">
                            <Typography variant="body1" className="m-0">
                                The following trace data shows the production
                                process of the selected process, through the
                                different nodes of the journey.
                            </Typography>
                        </div>
                        <div className="d-flex justify-content-start mt-3 text-start mx-2">
                            <Typography variant="h4" className="m-0 pb-2">
                                Composition
                            </Typography>
                        </div>

                        <div className="row no-margin flex-row flex-nowrap overflow-auto mx-2">
                            {compositionData?.map((comp) => (
                                <div className="card col-6 m-2 border-0">
                                    <div className="card-body">
                                        <div className="card-title">
                                            <Typography
                                                variant="body1"
                                                className="m-0 card-text-title"
                                                color="#70B8FF"
                                            >
                                                100%
                                            </Typography>
                                        </div>
                                        <div className="card-text">
                                            <Typography
                                                variant="subtitle"
                                                className="m-0 subtitle-card-text"
                                            >
                                                {comp["type"]} ({comp["asset"]})
                                            </Typography>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className="d-flex justify-content-start mt-3 text-start mx-2">
                            <Typography variant="h4" className="m-0 pb-2">
                                Process Stages
                            </Typography>
                        </div>
                        {nodeData &&
                            nodeData.map((node, index) => (
                                <div className="col-12" key={index}>
                                    <div className="card border-0 mb-3 mx-2">
                                        <div className="card-body">
                                            <Typography
                                                variant="h4"
                                                className="m-0"
                                            >
                                                {node.name}
                                            </Typography>
                                            <div className="divider-line my-3"></div>
                                            <div className="card-text">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <Typography
                                                        variant="subtitle"
                                                        className="m-0 subtitle-card-text"
                                                    >
                                                        {node.date}
                                                    </Typography>
                                                    <div
                                                        className="d-flex align-items-center"
                                                        onClick={
                                                            handleViewDetails
                                                        }
                                                    >
                                                        <Typography
                                                            variant="subtitle"
                                                            className="m-0 view-details-text d-flex align-items-center"
                                                        >
                                                            View Details
                                                            <Icon
                                                                className="ms-1"
                                                                src="/icons/linear-arrow-down.svg"
                                                            />
                                                        </Typography>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
            </div>
            <footer
                className=" p-3 text-center"
                style={{ backgroundColor: "#F2F5FA" }}
            >
                <Typography variant="subtitle" className="m-0">
                    Hyperglade, LLC
                </Typography>
            </footer>
        </div>
    );
}
