import { useMemo, useState } from "react";
import Button from "../Button/Button";
import "../forms/form.scss";
import Form from "./Form";
import TextField from "./TextField";
import TextArea from "./TextArea";
import { toast } from "react-toastify";
import { useUploadFileMutation } from "../../services/fileManagementService";
import LocationSearchSelect from "./LocationSearch";
import UnifiedFileUpload from "./UnifiedFileUpload";
import Select from "./Select";
import { useGetUnitsQuery } from "../../services/supplyChainService";

export default function AddForm({
    title,
    type,
    count,
    initialValues,
    onSubmit,
    isLoading = false,
    selectOptions = [],
    validationSchema,
    searchLocationsHook,
}) {
    const [forms, setForms] = useState([{}]);
    const [nodeImage, setNodeImage] = useState(null);
    const [uploadFile, { isLoading: isUploading }] = useUploadFileMutation();
    const addForm = () => setForms([...forms, {}]);
    const { data: unitsData, isLoading: isUnitsLoading } = useGetUnitsQuery();
    const categoryOptions = useMemo(() => {
        if (!unitsData?.data) return [];

        return unitsData.data.map((category) => ({
            label:
                category.name.charAt(0).toUpperCase() + category.name.slice(1),
            value: category.id,
            name: category.name,
        }));
    }, [unitsData]);

    const removeForm = (index) => {
        if (forms.length > 1) {
            setForms(forms.filter((_, i) => i !== index));
        }
    };

    const handleFileSelected = (file) => {
        setNodeImage(file);
    };

    const handleFormSubmit = async (values) => {
        try {
            if (nodeImage) {
                const formData = new FormData();
                formData.append("file", nodeImage);

                const uploadResponse = await uploadFile({
                    file: formData,
                }).unwrap();
                if (!uploadResponse?.data?.id) {
                    throw new Error("File upload failed");
                }

                // Call parent onSubmit with file ID
                onSubmit({
                    ...values,
                    nodeImage: uploadResponse.data.id,
                });
            } else {
                onSubmit(values);
            }
        } catch (error) {
            toast.error("File upload failed: " + error.message);
        }
    };

    return (
        <div>
            {forms.map((_, index) => (
                <div key={index} className="add-container">
                    <div className="d-flex justify-content-between m-3 add-header">
                        <div className="add-title">
                            {title} # {count}
                        </div>
                        <div
                            className="add-close"
                            onClick={() => removeForm(index)}
                        >
                            X Remove
                        </div>
                    </div>
                    <div>
                        <Form
                            initialValues={{
                                ...initialValues,
                                nodeImage: null,
                            }}
                            onSubmit={handleFormSubmit}
                            validationSchema={validationSchema}
                        >
                            {type === "node" && (
                                <div>
                                    <div className="m-3">
                                        <TextField
                                            name="nodeTitle"
                                            label="Node title"
                                        />
                                    </div>
                                    <div className="m-3">
                                        <TextArea
                                            name="description"
                                            label="Descriptions"
                                        />
                                    </div>
                                    <div className="m-3">
                                        <LocationSearchSelect
                                            name="location"
                                            label="Location"
                                            placeholder="Search for a location..."
                                            searchLocationsHook={
                                                searchLocationsHook
                                            }
                                        />
                                    </div>
                                    <div className="m-3">
                                        <UnifiedFileUpload
                                            name="nodeImage"
                                            fileType="image"
                                            label="Promo Image"
                                            onFileSelected={handleFileSelected}
                                            selectedFile={nodeImage}
                                            width="100%"
                                        />
                                    </div>
                                    <div className="m-3">
                                        <Button
                                            type="submit"
                                            variant="primary"
                                            fullWidth={false}
                                            isLoading={isLoading || isUploading}
                                        >
                                            Add Node
                                        </Button>
                                    </div>
                                </div>
                            )}
                            {type === "asset" && (
                                <div>
                                    <div className="m-3">
                                        <TextField
                                            name="assetTitle"
                                            label="Record type name"
                                        />
                                    </div>
                                    <div className="m-3">
                                        <Select
                                            name="unitCategory"
                                            label="Unit Category"
                                            options={categoryOptions}
                                            isLoading={isUnitsLoading}
                                            placeholder="Select unit category"
                                        />
                                    </div>
                                    <div className="m-3">
                                        <Button
                                            type="submit"
                                            variant="primary"
                                            fullWidth={false}
                                            isLoading={isLoading}
                                        >
                                            Add Record Type
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </Form>
                    </div>
                </div>
            ))}
            <div className="m-2 mb-4 add-another-node" onClick={addForm}>
                + Add another {type === "asset" ? "Asset" : "Node"}
            </div>
        </div>
    );
}
