import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Typography from "../../../components/Typography/Typography";
import Section from "./Section";
import { useLazyGetTraceAssetQuery } from "../../../services/supplyChainService";

function TraceDetailPage() {
    const navigate = useNavigate();
    const { projectId, assetId } = useParams();

    const [isLoading, setIsLoading] = useState(true); // Add this line

    const [getTrace, results] = useLazyGetTraceAssetQuery();

    const [nodeData, setNodeData] = useState(null);

    useEffect(() => {
        handleTraceAsset();
    }, [projectId, assetId]);

    const handleTraceAsset = async (values) => {
        try {
            setIsLoading(true);
            const trace = await getTrace({
                id: projectId,
                asset_id: assetId,
            });

            if (trace.error) {
                throw "Error in trace";
            }

            let _nodeData = [];
            for (var key in trace.data.data) {
                for (var asset of trace.data.data[key]) {
                    _nodeData.push(asset);
                }
            }
            setNodeData(_nodeData);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleBackClick = () => {
        navigate(`/app/${projectId}/trace/${assetId}`);
    };

    const processFormData = (formData) => {
        let formDetails = [];

        if(!formData){
            return [];
        }

        for(let item of formData){
            if(!item.value){
                continue;
            }

            if(item.type === 'group'){
                for(let valueSet of item.value){
                    let _valueSet = [];
                    for(let subItem of valueSet){
                        _valueSet.push({
                            key: subItem.label,
                            type: subItem.type,
                            value: subItem.value
                        })
                    }
                    formDetails.push({
                        type: "valueset",
                        value: _valueSet
                    })
                }
            }else{
                formDetails.push({
                    key: item.label,
                    type: item.type,
                    value: item.value
                })
            }
        }

        return formDetails;
    }

    if (!nodeData) {
        return (
            <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "100vh" }}
            >
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }

    return (
        <div className="container-fluid landing-container min-vh-100 h-100">
            <div className="landing-header d-flex align-items-center justify-content-center">
                <img
                    src="/images/hyperglade-logo-black.svg"
                    alt="logo"
                    height={50}
                    width={120}
                />
            </div>

            <div className="pt-2 ps-3 ps-md-0 d-flex justify-content-md-center">
                <span>
                    <Typography variant="body1" className="m-0">
                        Trace / {nodeData?.[0]?.asset_id}
                    </Typography>
                </span>
            </div>
            <div className="pt-2 ps-3 ps-md-0 d-flex justify-content-md-center">
                <Typography variant="body1" color="#70B8FF" className="m-0">
                    {nodeData?.[0]?.asset_id}
                </Typography>
            </div>
            {nodeData?.map((product) => (
                <Section
                    title={product.node.name}
                    assetId={product.asset_id}
                    description={product.node.description}
                    location={product.node.location}
                    mapImage="/images/g-map.png"
                    details={processFormData(product.form_data)}
                    images={[product.node.promo_image]}
                    id="carousel1"
                    tokenId={product.token_id}
                    txnHash={product.transaction_hash}
                    contractAddress={product.contract_address}
                    nodeFormData={product.form_data}
                    chain={product.chain}
                />
            ))}
            <footer
                className=" p-3 text-center"
                style={{ backgroundColor: "#F2F5FA" }}
            >
                <Typography variant="subtitle" className="m-0">
                    Hyperglade, LLC
                </Typography>
            </footer>
        </div>
    );
}

export default TraceDetailPage;
